import React from 'react';
import Section from '../../../layout/Section/Section';
import * as styles from './learn-more.module.scss';
import Arrow from '../../../../assets/images/svg/arrow.svg';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';


export function LearnMore() {
  const { t } = useTranslation( 'learnMore' );
  const options   = t( 'options', { returnObjects: true } );
  const tutorials = t( 'tutorials', { returnObjects: true } );
  const apis      = t( 'apis', { returnObjects: true } );
  const extension = t( 'extension', { returnObjects: true } );

  const settings = [
    {
      ...options,
      to: '/guides/options/',
    },
    {
      ...tutorials,
      to: '/tutorials/',
    },
    {
      ...apis,
      to: '/guides/apis/',
    },
    {
      ...extension,
      to: '/guides/extension/',
    },
  ];

  return (
    <Section heading="Learn More">
      <div className={ styles.learnMoreInner }>
        { settings.map( ( setting, index ) => {
          return (
            <section className={ styles.learnMoreSection } key={ index }>
              <div className={ styles.learnMoreContent }>
                <h3>{ setting.heading }</h3>
                <p>
                  { setting.desc }
                </p>

                <Link to={ setting.to } className={ styles.learnMoreLink }>
                  <Arrow />
                  <span>{ setting.link }</span>
                </Link>
              </div>
            </section>
          );
        } ) }
      </div>
    </Section>
  );
}