import React from 'react';
import Chat from '../../../../assets/images/svg/chat-light.svg';
import * as styles from './news.module.scss';
import { Trans } from 'react-i18next';
import { Link } from 'gatsby';


export function News() {
  return (
    <aside className={ styles.news }>
      <Chat />
      <span>
        <Trans i18nKey="news">
          <Link to={ '/guides/version4/' }>Splide v4</Link> has been released!
        </Trans>
      </span>
    </aside>
  );
}