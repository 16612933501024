import React from 'react';
import Section from '../../../layout/Section/Section';
import { ShaderCarousel } from '../../../components/ShaderCarousel/ShaderCarousel';
import * as styles from './premium.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import ArrowAlt from '../../../../assets/images/svg/arrow-alt.svg';
import { Link } from 'gatsby';

export function Premium() {
  const { t } = useTranslation( 'premium' );

  return (
    <Section className={ styles.section } isWide>
      <div className={ styles.wrapper }>
        <div className={ styles.carousel }>
          <ShaderCarousel />
        </div>

        <div className={ styles.content }>
          <header className={ styles.header }>
            <p>Released!</p>
            <h2>Splide Premium</h2>
          </header>

          <div className={ styles.desc }>
            <p>
              <Trans ns="premium" i18nKey="mainDescription1">
                Enrich your next project with <span>impressive transition effects by Splide and WebGL (three.js).</span>
              </Trans>
            </p>
            <p>{ t( 'mainDescription2' ) }</p>
          </div>

          <div className={ styles.link }>
            <Link to={ '/premium/' }>More Examples<ArrowAlt /></Link>
          </div>
        </div>
      </div>
    </Section>
  );
}