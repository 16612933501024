import React from 'react';
import Layout from '../components/layout/Layout/Layout';
import { About, Examples, Header, LearnMore, Premium } from '../components/scenes/Home';
import { SEO } from '../components/seo/SEO/SEO';
import Serif from '../assets/fonts/splide-serif/SplideSerif.woff2';


export default function Home() {
  return (
    <Layout>
      <Header />
      <Premium />
      <Examples />
      <About />
      <LearnMore />
    </Layout>
  );
}

export function Head() {
  return (
    <>
      <SEO />
      <link rel="preload" href="/images/slides/full/01.webp" as="image" />
      <link rel="preload" href={ Serif } as="font" type="font/woff2" crossOrigin="anonymous" />
    </>
  );
}