import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'gatsby';


export const EXAMPLES = [
  {
    id: 'default',
  },
  {
    id: 'sidePadding',
  },
  {
    id            : 'multipleSlides',
    usePlaceholder: true,
  },
  {
    id: 'perMove',
    i18n: 'oneSlidePerMove',
  },
  {
    id: 'omitEndFocus',
    i18n: 'omitEnd',
    desc: 'omitEndDesc',
    // desc: () => (
    //   <p>
    //     <Trans i18nKey="omitEndDesc" ns="examples">
    //       From v4.1.0, the <code>omitEnd</code> option allows you to disable redundant navigations in the last page. For example, the following carousel contains 9 slides, but pagination has 7 dots, omitting the last 2 dots.
    //     </Trans>
    //   </p>
    // ),
  },
  {
    id            : 'focusCenter',
    usePlaceholder: true,
  },
  {
    id            : 'dragFree',
    usePlaceholder: true,
  },
  {
    id            : 'dragFreeSnap',
    usePlaceholder: true,
  },
  {
    id            : 'customPagination',
    pagination    : true,
    usePlaceholder: true,
  },
  {
    id            : 'sliderProgress',
    sliderProgress: true,
    usePlaceholder: true,
  },
  {
    id            : 'ttb',
    i18n          : 'verticalSlider',
    sliderProgress: true,
    desc          : () => (
      <p>
        <Trans i18nKey="verticalSliderDesc" ns="examples">
          The vertical slider requires the <code>height</code> or <code>heightRatio</code> option
          to determine the slider height.
        </Trans>
      </p>
    ),
  },
  {
    id            : 'mouseWheel',
    usePlaceholder: true,
    desc          : () => (
      <p>
        <Trans i18nKey="mouseWheelDesc" ns="examples">
          You will need to set <code>waitForTransition</code> to <code>true</code> or provide the <code>wheelSleep</code> duration.
        </Trans>
      </p>
    ),
  },
  {
    id            : 'autoWidth',
    usePlaceholder: true,
    desc          : 'autoWidthDesc',
  },
  {
    id            : 'autoplay',
    usePlaceholder: true,
    triggerOnce   : false,
    desc          : 'autoplayDesc',
  },
  {
    id            : 'fadeTransition',
    usePlaceholder: true,
    desc          : 'fadeTransitionDesc',
  },
  {
    id            : 'rtl',
    i18n          : 'rightToLeft',
    usePlaceholder: true,
  },
  {
    id            : 'nestedSlider',
    usePlaceholder: true,
  },
  {
    id            : 'breakpoints',
    usePlaceholder: true,
    desc          : 'breakpointsDesc',
  },
  {
    id          : 'addRemove',
    desc        : 'addRemoveDesc',
    hasAddRemove: true,
  },
  // {
  //   id            : 'cover',
  //   i18n          : 'coverImage',
  //   usePlaceholder: true,
  //   desc          : 'coverImageDesc',
  // },
  {
    id            : 'lazyLoad',
    usePlaceholder: true,
    desc          : 'lazyLoadDesc',
  },
  {
    id            : 'thumbnail-navigation',
    i18n          : 'thumbnails',
    usePlaceholder: true,
    desc          : 'thumbnailsDesc',
  },
  {
    id            : 'autoScroll',
    usePlaceholder: true,
    autoScroll    : true,
    triggerOnce   : false,
    asterisk      : true,
    desc          : () => (
      <p>
        <Trans i18nKey="autoScrollDesc" ns="examples">
          The AutoScroll extension continuously scrolls the slider (<Link to={ 'extensions/auto-scroll' }>AutoScroll extension</Link> is required).
        </Trans>
      </p>
    ),
  },
  {
    id            : 'video',
    usePlaceholder: true,
    video         : true,
    asterisk      : true,
    desc          : () => (
      <p>
        <Trans i18nKey="videoDesc" ns="examples">
          The Video extension assigns HTML, YouTube and Vimeo videos to slides (<Link to={ 'extensions/video' }>Video extension</Link> is required).
        </Trans>
      </p>
    ),
  },
  {
    id            : 'grid',
    usePlaceholder: true,
    grid          : true,
    asterisk      : true,
    desc          : () => (
      <p>
        <Trans i18nKey="gridDesc" ns="examples">
          The Grid extension creates rows and cols in a slider (<Link to={ 'extensions/grid' }>Grid extension</Link> is required).
        </Trans>
      </p>
    ),
  },
  {
    id            : 'url-hash',
    i18n          : 'urlHashNavigation',
    usePlaceholder: true,
    urlHash       : true,
    asterisk      : true,
    desc          : () => (
      <p>
        <Trans i18nKey="urlHashNavigationDesc" ns="examples">
          The URL Hash extension enables to sync the slider with the URL hash,
          and update the hash when the active slide changes (<Link to={ 'extensions/url-hash' }>URL Hash extension</Link> is required).
        </Trans>
      </p>
    ),
  },
];