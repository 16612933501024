// extracted by mini-css-extract-plugin
export var header = "header-module--header--iT-ym";
export var headerContent = "header-module--header-content--W4XVT";
export var headerContentOverlay = "header-module--header-content-overlay--GhbA0";
export var headerContentSlider = "header-module--header-content-slider--GHEXx";
export var headerHeading = "header-module--header-heading--97xAs";
export var headerIntro = "header-module--header-intro---eEkB";
export var headerIntroMulti = "header-module--header-intro--multi--ojTxZ";
export var headerVersion = "header-module--header-version--wbNU4";
export var headerActions = "header-module--header-actions--fqGJB";
export var headerBgText = "header-module--header-bg-text--mazEX";
export var splideSlideDown = "header-module--splide-slide-down--IGCWL";