import React from 'react';
import Section from '../../../layout/Section/Section';
import * as styles from './about.module.scss';
import Check from '../../../../assets/images/svg/check.svg';
import { useTranslation } from 'react-i18next';
import { classNames} from '../../../../utils';


export function About() {
  const { t } = useTranslation( 'about' );
  const features = t( 'features', { returnObjects: true } );
  const desc     = t( 'desc', { returnObjects: true } );

  return (
    <Section heading="About Splide" isNarrow className={ classNames( styles.about, 'has-gradient-bg' ) }>
      { desc.map( ( desc, index ) => <p key={ index }>{ desc }</p> ) }

      <ul className={ styles.aboutFeatures }>
        { features.map( ( feature, index ) => (
          <li className={ styles.aboutFeature } key={ index }>
            <span className={ styles.aboutFeatureIcon }><Check /></span>
            <span className={ styles.aboutFeatureText }>{ feature }</span>
          </li>
        ) ) }
      </ul>
    </Section>
  );
}