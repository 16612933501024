import React from 'react';
import Section from '../../../layout/Section/Section';
import { Example } from '../../../components/Example/Example';
import { useTranslation } from 'react-i18next';
import { EXAMPLES } from './constants';
import { toKebab } from '../../../../utils';
import * as styles from './examples.module.scss';


const KEYS_TO_PROPAGATE = [
  'usePlaceholder',
  'pagination',
  'sliderProgress',
  'autoScroll',
  'triggerOnce',
  'video',
  'grid',
  'urlHash',
  'hasAddRemove',
];

export function Examples() {
  const { t } = useTranslation( 'examples' );

  return (
    <Section hasSidebar hasContentStyles isHome>
      <h2>{ t( 'examples' ) }</h2>

      { EXAMPLES.map( example => {
        const { id, sourceId, i18n, asterisk } = example;
        const kebabId   = toKebab( id );
        const headingId = `${ kebabId }-heading`;

        return (
          <section key={ id } className={ styles.examplesSection }>
            <h3 id={ headingId }>{ t( i18n || id ) }{ asterisk ? '*' : '' }</h3>
            { renderDesc( example.desc ) }
            <Example
              id={ kebabId }
              sourceId={ sourceId || kebabId }
              labelledby={ headingId }
              { ...KEYS_TO_PROPAGATE.reduce( ( props, key ) => {
                props[ key ] = example[ key ];
                return props;
              }, {} ) }
            />
          </section>
        );
      } ) }
    </Section>
  );
}

function renderDesc( desc ) {
  const { t } = useTranslation( 'examples' );

  if ( desc ) {
    if ( typeof desc === 'string' ) {
      return (
        <p>{ t( desc ) }</p>
      );
    } else if ( typeof desc === 'function' ) {
      return desc();
    }
  }

  return null;
}