import React, { useEffect, useRef } from 'react';
import * as styles from './header.module.scss';
import Logo from '../../../../assets/images/svg/logo-text.svg';
import LogoText from '../../../../assets/images/svg/logo-text-only.svg';
import { Slider } from '../../../components/Slider/Slider';
import { addClass, queryAll } from '@splidejs/splide/src/js/utils';
import { Button } from '../../../components/Button/Button';
import { News } from '../News/News';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../../../utils';
import { InView } from 'react-intersection-observer';


const ANIMATION_DELAY = 50;

export function Header() {
  const ref = useRef( null );
  const { t, i18n } = useTranslation( 'common' );

  useEffect( () => {
    const slides = queryAll( ref.current.node, '.splide__slide' );

    slides.forEach( ( slide, index ) => {
      setTimeout( () => {
        addClass( slide, 'is-triggered' );
      }, index * ANIMATION_DELAY );
    } );
  } );

  let splide;

  const onChange = inView => {
    if ( splide ) {
      const { Autoplay } = splide.Components;
      inView ? Autoplay.play() : Autoplay.pause();
    }
  };

  return (
    <header className={ classNames( styles.header, 'has-gradient-bg' ) }>
      <div className={ styles.headerContent }>
        <div className={ styles.headerContentOverlay }>
          <div>
            <h1 className={ styles.headerHeading }>
              <span className="visually-hidden">Splide</span>
              <Logo/>
            </h1>

            <div className={ classNames( styles.headerIntro, i18n.language !== 'en' && styles.headerIntroMulti ) }>
              { t( 'intro', { returnObjects: true } ).map( ( text, index ) => {
                return (
                  <p key={ index }>
                    { text.split( '\n' ).map( ( fragment ) => <span key={ fragment }>{ fragment }</span> ) }
                  </p>
                );
              } ) }
            </div>
          </div>
        </div>

        <InView
          as={ 'div' }
          className={ styles.headerContentSlider }
          role="presentation"
          ref={ ref }
          onChange={ onChange }
        >
          <Slider
            id="home-background-slider"
            sourceId="home-background"
            theme="home"
            delay={ 2000 }
            onMount={ instance => { splide = instance } }
            options={ {
              role: 'group',
            } }
          />
        </InView>
      </div>

      <div className={ styles.headerActions }>
        <Button isPrimary newTab href="https://github.com/Splidejs/splide/releases/latest/">
          { t( 'download' ) }
        </Button>
        <Button isPrimary to="/guides/getting-started/">
          { t( 'getStarted' ) }
        </Button>
      </div>

      <News />

      <div className={ styles.headerBgText } aria-hidden="true" role="presentation">
        <LogoText />
      </div>
    </header>
  );
}